<template>
  <div>
    <el-config-provider :locale="locale">
      <router-view v-slot="{ Component }">
        <transition name="app-fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </el-config-provider>
  </div>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import elementLangZhTw from 'element-plus/lib/locale/lang/zh-tw'
export default {
  name: 'vue-App',
  components: {
    ElConfigProvider,
  },
  data(){
    return {
      locale: elementLangZhTw,
    }
  }
}
</script>

<style lang="scss">
</style>
