import { createRouter, createWebHistory } from 'vue-router'
import axios from "axios"

const routes = [
  {
    path: '/',
    name: 'layout',
    component: () => import('@/layout/body.vue'),
    redirect: "station",
    children: [
      {
        path: '/dashboard',
        name: '首頁',
        component: () => import('@/pages/dashboard.vue')
      },
      {
        path: '/station',
        name: '電站管理',
        component: () => import('@/pages/station.vue')
      },
      {
        path: '/alarm',
        name: '告警',
        component: () => import('@/pages/alarm.vue')
      },
      {
        path: '/deepLearning',
        name: 'AI檢知',
        component: () => import('@/pages/deepLearning.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login.vue')
  },
  {
    path: "/logout",
    component: {
      render(){
        console.log()
        axios.get("/logout")
        .finally(
          function(){
            window.location.href = '/login'
          }
        )
      }
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
