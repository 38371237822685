import { createStore } from 'vuex'

export default createStore({
  state: {
    isMobile: false,
    navOpen: true,
    navWidth: "160px",
    user_data: {
      level: 1,
      superuser: false
    },
  },
  getters: {
  },
  mutations: {
    setMobile(state, is_mobile){
      state.isMobile = is_mobile
    },
    set_nav_open(state, data){
      state.navOpen = data
    },
    set_nav_width(state, width){
      state.navWidth = width
    },
    set_user_data(state, data){
      state.user_data = data
    },
  },
  actions: {
  },
  modules: {
  }
})
